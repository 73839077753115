import home from '../assets/img/icon/home.svg'
import service from '../assets/img/icon/settings.svg'
import developer from '../assets/img/icon/developer.svg'
import partner from '../assets/img/icon/partner.svg'
import about from '../assets/img/icon/AboutUsIcon.svg'
import integration from '../assets/img/icon/integration.svg'
import logo2 from '../assets/img/block/LogoHosted.svg'
import logo1 from '../assets/img/block/LogoLightCloud.svg'
import logo3 from '../assets/img/block/CallCenterLogo.svg'

export const menu = [
    {id: 1, icon: home, link: '/', trans: ['', 'Home', 'Главная', ''], isParent: 0},
    {
        id: 2,
        icon: service,
        link: '/services/self_hosted',
        trans: ['', 'Service', 'Услуга', 'Ծառայություններ'],
        isParent: 1,
        child:
            [
                {
                    id: 1, icon: logo1,
                    link: ['', '/services/Internetpbx', '/services/virtualniats', '/services/virtualpbx'],
                    trans: ['', 'Cloud-based PBX', 'Облачная АТС', 'Վիրտուալ PBX'], isParent: 0
                },
                {
                    id: 2, icon: logo2,
                    link: ['', '/services/localpbx', '/services/localniats', '/services/local'],
                    trans: ['', 'Self-Hosted PBX', 'Автономная АТС', 'Լոկալ PBX'], isParent: 0
                },
                {
                    id: 3, icon: logo3,
                    link: ['', '/services/callcenter', '/services/callcenter', '/services/callcenter'],
                    trans: ['', 'Call-center build', 'Построение колл-центра', 'Զանգերի կենտրոն'], isParent: 0
                },
            ]
    },
    {id: 4, icon: integration, link: '/crm', trans: ['', 'Integrations', 'Интеграции', 'Ինտեգրումներ'], isParent: 0},
    {id: 3, icon: about, link: '/about', trans: ['', 'About us', 'О нас', 'Մեր մասին'], isParent: 0},
    {id: 5, icon: developer, link: '/api_document', trans: ['', 'Developers kit', 'API', 'API'], isParent: 0},
    {id: 6, icon: partner, link: '/partner', trans: ['', 'Partnership', 'Партнерство', 'Գործընկերներ'], isParent: 0},
    {
        id: 7,
        icon: partner,
        link: window.innerWidth > 786 ? '#register' : '/register',
        trans: ['', 'Sign in', 'Войти', 'Մուտք գործել'],
        isParent: 0
    },
    {
        id: 8,
        icon: partner,
        link: window.innerWidth > 786 ? '#login' : '/login',
        trans: ['', 'Get free access', 'Бесплатный доступ', 'Անվճար մուտք'],
        isParent: 0
    },

]
export const menuPrice = {
    team: ['', 'Our members', 'Наша команда', 'Մեր թիմը'],
    offer: ['', 'Offer', 'Оферта', 'Առաջարկ'],
    money: ['', '֏', '֏', '֏'],
    title: ['', 'Calculate your contribution/month', 'Рассчитайте свой месячный вклад', 'Հաշվարկեք Ձեր ամսական ներդրումը'],
    all: ['', '15000', '15000', '15000'],
    service: ['', 'Service name', 'Имя услуги', 'Ծառայության անվանումը'],
    sprice: ['', 'Service price (days)', 'Стоимость услуги (день)', 'Ծառայության արժեքը (օր)'],
    count: ['', 'Count', 'Подсчет', 'Քանակ'],
    sum: ['', 'Amount', 'Сумма', 'Գումար'],
    allSum: ['', 'Your monthly contribution will be', 'Ваш ежемесячный взнос составит', 'Ձեր ամսական ներդրումը կկազմի'],
    calc: ['', 'Calculator', 'Калькулятор', 'Հաշվիչ'],
    test: ['', 'Try free', 'Попробовать бесплатно', 'Փորձեք անվճար'],
    sell: ['', 'Order service', 'Заказать услугу', 'Պատվիրեք ծառայություն'],
}
export const homeD = {
    title:['','Advanced Telephone Systems (ATS)','Адванст Телефон Системс (АТС)','Advanced Telephone Systems (ԱՏՍ)'],
    solution: ['', 'Use the features of the system for free 15 days, |check it out', 'Используйте возможности системы бесплатно в течение 15 дней, |проверьте сейчас', 'Օգտվել անվճար  համակարգի հնարավորություններից 15 օր շարունակ |փորձել անվճար'],
    operator: {
        title: ['', 'Install your own ', 'Установите ', 'Տեղադրեք ձեր '],
        link: ['', 'local PBX', 'локальную АТС', 'լոկալ PBX-ը'],
        desc: ['', 'A local PBX (Private Branch Exchange) is a private telephone network used within an organization. It allows internal communication and external communication through different communication channels, such as traditional phone lines, VoIP, or other methods.',
            'Локальная АТС (Private Branch Exchange) — это частная телефонная сеть, используемая в организации. Она обеспечивает внутреннюю и внешнюю связь через различные каналы связи, такие как традиционные телефонные линии, VoIP или другие методы.\n',
            'Լոկալ PBX-ը (Private Branch Exchange) մասնավոր հեռախոսային ցանց է, որն օգտագործվում է կազմակերպության ներսում: Այն թույլ է տալիս ներքին և արտաքին հաղորդակցություն տարբեր ուղիներով, ինչպիսիք են հեռախոսագծերը, VoIP կամ այլ մեթոդները:\n']
    },
    login: ['', 'Visit you personal area', 'Посетите личный кабинет', 'Մուտք գործել անձնական հաշիվ'],
    register: ['', 'Create your own Virtual PBX', 'Создайте свою собственную виртуальную АТС', 'Ստեղծեք ձեր սեփական վիրտուալ PBX-ը'],
    download: ['', 'See more', 'Узнать больше', 'Տեսնել ավելին'],
    download_wrapper: ['', 'Download mobile applications', 'Загрузите мобильные приложения', 'Ներբեռնեք բջջային հավելվածներ'],
    download_desc: ['', 'For more convenience and control, download client applications for your mobile device', 'Для большего удобства и контроля скачайте клиентские приложения на мобильное устройство.', 'Ավելի հարմարության և վերահսկման համար ներբեռնեք հաճախորդի հավելվածները ձեր բջջային սարքի համար'],
    api_desc: ['', 'A simple description of the methods for integrating our system into your CRM', 'Простое описание способов интеграции нашей системы в вашу CRM', 'Մեր համակարգը ձեր CRM-ում ինտեգրելու մեթոդների պարզ նկարագրություն'],
    api_desc1: ['', 'API for developers', 'API для разработчиков', 'API ծրագրավորողների համար'],
    api_title: ['', 'API for developers', 'API документация для разработчиков', 'API դոկումենտացիա'],
    video_title: ['', 'Why Choose', 'Почему выбрать', 'Ինչու՞ ընտրել'],
    example: ['', 'For example', 'Например', 'Օրինակ'],
    partner: ['', 'Our partner companies', 'Наши партнеры', 'Մեր գործընկերները'],
    download_m: ['', 'For example', 'Загрузка файла', 'Ֆայլի ներբեռնում'],
    crm: ['', 'Customer relationship management', 'Управление взаимоотношениями с клиентами', 'Հաճախորդների հետ փոխհարաբերությունների կառավարում'],
    api_item: ['', 'You can get the key through Your personal page personal page. Available only to ATS partner and users.',
        'Вы можете получить ключ через Вашу персональную страницу. Доступно только пользователям и партнеров ATS.',
        'Բանալին կարող եք ստանալ Ձեր անձնական էջից: Հասանելի է միայն ATS գործընկերի և օգտվողների համար:'],
    activeCall: ['', 'You can get the key through Your personal page personal page. Available only to ATS partner and users.',
        'Вы можете получить ключ через Вашу персональную страницу. Доступно только пользователям и партнеров ATS.',
        'Բանալին կարող եք ստանալ Ձեր անձնական էջից: Հասանելի է միայն ATS գործընկերի և օգտվողների համար:'],

}

export const buttons = {
    top: ['', 'Get free access', 'Бесплатный доступ', 'Օգտվել անվճար'],
    sign: ['', 'Sign in', 'Войти', 'Մուտք գործել'],
    reg: ['', 'Register for free', 'Беспл․ регистрация', 'Գրանցվեք անվճար'],
    request: ['', 'Request integration', 'Запросить интеграцию', 'Ինտեգրման հայտ'],
    forget: ['', 'Forget password?', 'Забыли пароль?', 'Մոռացե՞լ եք գաղտնաբառը'],
    email: ['', 'Your phone number', 'Ваш номер телефона', 'Հեռախոսահամար'],
    pass: ['', 'Enter your password', 'Введите ваш пароль', 'Մուտքագրեք Ձեր գաղտնաբառը'],
    desc: ['', "You don't have a personal account yet.", 'У вас еще нет личного кабинета?', 'Դեռևս չունե՞ք անձնական հաշիվ'],
    res: ['', "Reset password", 'Сбросить пароль', 'Փոխել գաղտնաբառը'],

}

export const meta = {
    home_title: ['',
        'Advanced Telephone Systems - ATS',
        'Advanced Telephone Systems  - АТС',
        'Advanced Telephone Systems - ԱՏՍ'],
    home_desc: ['', 'Discover reliable cloud PBX, local PBX, and virtual PBX solutions with Advanced Telephone Systems (ATS). Our PBX services are designed to enhance business communication with advanced features, seamless scalability, and 24/7 support.',
        'Откройте для себя надежные решения облачной АТС, локальной АТС и виртуальной АТС с Advanced Telephone Systems (АТС). Наши услуги АТС предназначены для улучшения бизнес-коммуникаций с помощью расширенных функций, бесшовной масштабируемости и круглосуточной поддержки.',
        'Բացահայտեք ամպային PBX, ներքին PBX և PBX հսկողության լուծումներ Advanced Telephone Systems -ի (ԱՏՍ) միջոցով: Մեր PBX ծառայությունները բարելավում են ձեր բիզնես հաղորդակցությունը առաջադեմ գործառույթներով, մասշտաբայնությամբ և 24/7 աջակցությամբ:'],
    crm_title: ['',
        'CRM integrations - ATS',
        'Интеграция с CRM-системами - АТС',
        'CRM համակարգի ինտեգրում - ԱՏՍ'],
    crm_desc: ['', 'Optimize business communication with Advanced Telephone Systems (ATS). Our cloud PBX solutions offer seamless CRM integrations, advanced call management, and scalable phone systems. Streamline operations with reliable service and 24/7 support.',
        'Оптимизируйте деловую коммуникацию с помощью Advanced Telephone Systems (АТС). Наши облачные решения PBX предлагают бескабельную интеграцию CRM, расширенное управление вызовами и масштабируемые телефонные системы. Оптимизируйте операции с надежным обслуживанием и круглосуточной поддержкой.',
        'Օպտիմալացրեք ձեր բիզնես հաղորդակցությունը Advanced Telephone Systems -ի (ԱՏՍ) միջոցով: Cloud-ի վրա հիմնված մեր PBX լուծումներն առաջարկում են առանց մալուխի CRM ինտեգրում, առաջադեմ զանգերի կառավարում և ընդլայնելի հեռախոսային համակարգեր: Հեշտացնել գործառնությունները հուսալի սպասարկմամբ և 24/7 աջակցությամբ:'],

    about_title: ['',
        'About us - ATS',
        'О нас - АТС',
        'Մեր Մասին - ԱՏՍ'],
    about_desc: ['', 'Learn about Advanced Telephone Systems (ATS), a leading Armenian IT company providing innovative PBX solutions. Discover how our cloud-based systems empower businesses with seamless communication, scalability, and expert support.',
        'Узнайте об Advanced Telephone Systems (АТС), ведущей армянской ИТ-компании, предоставляющей инновационные решения PBX. Узнайте, как наши облачные системы расширяют возможности бизнеса с помощью бесперебойной связи, масштабируемости и экспертной поддержки.',
        'Իմացեք Advanced Telephone Systems-ի (ԱՏՍ) հայկական առաջատար ՏՏ ընկերության մասին, որը տրամադրում է նորարարական PBX լուծումներ: Իմացեք, թե ինչպես են մեր ամպային համակարգերը հզորացնում ձեր բիզնեսը անխափան կապով, մասշտաբայնությամբ և փորձագիտական ​​աջակցությամբ:'],
    partner_title: ['',
        'Our Partners - ATS',
        'Наши партнеры - АТС',
        'Մեր գործընկերները - ԱՏՍ'],
    partner_desc: ['', 'Explore Advanced Telephone Systems (ATS) partnerships with leading companies who trust and implement our cloud PBX and call center solutions. Discover how our collaborative efforts drive innovation and deliver seamless communication services to businesses worldwide.',
        'Изучите партнерские отношения Advanced Telephone Systems (АТС) с ведущими компаниями, которые доверяют и внедряют наши облачные решения PBX и колл-центров. Узнайте, как наши совместные усилия стимулируют инновации и предоставляют бесперебойные коммуникационные услуги компаниям по всему миру.',
        'Ուսումնասիրեք Advanced Telephone Systems-ի (ԱՏՍ) համագործակցությունը առաջատար ընկերությունների հետ, որոնք վստահում և իրականացնում են մեր ամպի վրա հիմնված PBX և զանգերի կենտրոնի լուծումները: Իմացեք, թե ինչպես են մեր համատեղ ջանքերը մղում նորարարություններին և անխափան հաղորդակցման ծառայություններ մատուցում ընկերություններին ամբողջ աշխարհում:'],
    api_title: ['',
        'Developer\'s kit - ATS',
        'API документация - АТС',
        'API և փաստաթղթավորում - ԱՏՍ'],
    api_desc: ['', 'Unlock the power of Advanced Telephone Systems (ATS) with our comprehensive API and Developer Kit. Access robust APIs designed for seamless integration with your applications, empowering your development team to create customized communication solutions. Explore our detailed documentation, sample codes, and resources to enhance your projects with ATS’s cloud PBX capabilities. Start building innovative solutions today',
        'Откройте для себя мощь Advanced Telephone Systems (АТС) с нашим комплексным API и комплектом разработчика. Получите доступ к надежным API, разработанным для бесшовной интеграции с вашими приложениями, что позволит вашей команде разработчиков создавать индивидуальные решения для связи. Изучите нашу подробную документацию, примеры кодов и ресурсы, чтобы улучшить свои проекты с помощью возможностей облачной АТС. Начните создавать инновационные решения уже сегодня',
        'Բացահայտեք Advanced Telephone Systems-ի (ԱՏՍ) հզորությունը մեր համապարփակ API-ի և զարգացման փաթեթի միջոցով: Մուտք գործեք ամուր API-ներ, որոնք նախատեսված են ձեր հավելվածների հետ անխափան ինտեգրվելու համար՝ թույլ տալով ձեր զարգացման թիմին ստեղծել հատուկ հաղորդակցման լուծումներ: Բացահայտեք մեր մանրամասն փաստաթղթերը, կոդերի օրինակները և ռեսուրսները՝ ձեր նախագծերը Cloud PBX-ի հզորությամբ բարելավելու համար: Սկսեք ստեղծել նորարարական լուծումներ հենց այսօր'],
    virtual_title: ['',
        'Virtual PBX (VPBX) - ATS',
        'Виртуальный АТС - АТС',
        'Վիրտուալ PBX - ԱՏՍ'],
    virtual_desc: ['',
        'Enhance business communication with Advanced Telephone Systems (ATS). Our VPBX, Virtual PBX, and Internet PBX solutions offer seamless internet telephony with advanced features. Discover scalable PBX systems tailored to your needs - virtual ATS, call management, and more.',
        'Улучшите деловую коммуникацию с помощью Advanced Telephone Systems (АТС). Откройте для себя масштабируемые системы Виртуального АТСа (Облочный АТС), адаптированные под ваши потребности — виртуальные АТС, управление вызовами и многое другое.',
        'Բարելավեք ձեր բիզնես հաղորդակցությունը Advanced Telephone Systems-ի (ԱՏՍ) միջոցով: Բացահայտեք ձեր կարիքներին հարմարեցված Վիրտուալ ԱՏՍ (Cloud ԱՀԿ) ընդլայնելի համակարգեր՝ Վիրտուալ ավտոմատ կայան, զանգերի կառավարում և այլն:'],
    local_title: ['',
        'Local private branch exchange - ATS',
        'Локальный АТС - АТС',
        'Լոկալ ԱՀԿ - ԱՏՍ'],
    local_desc: ['',
        'Improve business communication with Advanced Telephone Systems (ATS). Our local PBX solutions offer reliable call management, IVR, and seamless connectivity. Discover the benefits of a local private branch exchange with expert support and easy scalability for businesses of all sizes.',
        'Улучшите деловую коммуникацию с помощью Advanced Telephone Systems (АТС). Наши локальные решения АТС предлагают надежное управление вызовами, IVR и бесперебойную связь. Откройте для себя преимущества локальной частной телефонной станции с экспертной поддержкой и легкой масштабируемостью для предприятий любого размера.',
        'Բարելավեք ձեր բիզնես հաղորդակցությունը Advanced Telephone Systems-ի (ԱՏՍ) միջոցով: Մեր Լոկալ PBX (ԱՀԿ) լուծումներն առաջարկում են զանգերի հուսալի կառավարում, IVR և անխափան հաղորդակցություն: Բացահայտեք Լոկալ ավտոմատ հեռախոսային կայանի առավելությունները՝ փորձագիտական ​​աջակցությամբ և հեշտ մասշտաբայնությամբ ցանկացած չափի բիզնեսի համար:'],
    call_title: ['',
        'Call centre service - ATS',
        'Колл центр - АТС',
        'Զանգերի կենտրոն - ԱՏՍ'],
    call_desc: ['',
        'Advanced Telephone Systems (ATS) offers reliable cloud PBX and call center services to streamline your business communication. Enhance your customer service with advanced features like call routing, IVR, and multi-channel support. Scalable solutions with 24/7 assistance.',
        'Advanced Telephone Systems (АТС) предлагает надежные облачные АТС и услуги колл-центра для оптимизации вашей деловой коммуникации. Улучшите обслуживание клиентов с помощью расширенных функций, таких как маршрутизация вызовов, IVR и многоканальная поддержка. Масштабируемые решения с круглосуточной поддержкой.',
        'Advanced Telephone Systems -ը (ԱՏՍ) առաջարկում է հուսալի ամպի վրա հիմնված PBX և զանգերի կենտրոնի ծառայություններ՝ ձեր բիզնես հաղորդակցությունն օպտիմալացնելու համար: Բարելավեք ձեր հաճախորդների փորձը առաջադեմ գործառույթներով, ինչպիսիք են զանգերի երթուղին, IVR և omnichannel աջակցությունը: Սանդղելի լուծումներ 24/7 աջակցությամբ:'],
    contact_title: ['', 'Contact us - ATS', 'Обратная связь - АТС', 'Հետադարձ կապ - ԱՏՍ'],
    contact_desc: ['', 'Get in touch with Advanced Telephone Systems (ATS) for all your cloud PBX and communication needs. Find our location, address, and company details, or reach out to our support team for assistance. We’re here to help you connect seamlessly.',
        'Свяжитесь с Advanced Telephone Systems (ATS) для всех ваших потребностей в облачной АТС и связи. Найдите наше местоположение, адрес и данные компании или обратитесь в нашу службу поддержки за помощью. Мы здесь, чтобы помочь вам подключиться без проблем.',
        'Կապ հաստատեք Advanced Telephone Systems (ATS) հետ ձեր բոլոր ամպային PBX և կապի կարիքների համար: Տեսեք մեր գտնվելու վայրը, հասցեն և ընկերության մանրամասները կամ օգնության համար դիմեք մեր աջակցության թիմին: Մենք այստեղ ենք՝ օգնելու ձեզ անխափան կապ հաստատել:'],

}

export const contactUs = {
    title: ['', 'Contact us - ATS', 'Обратная связь - АТС', 'Հետադարձ կապ - ԱՏՍ'],
    titleA: ['', 'Contact us', 'Свяжитесь с нами', 'Կապվեք մեզ հետ'],
    titleB: ['', 'Submit an integration request for CRM integration', 'Отправьте заявку на интеграцию', 'Ուղարկել Համագործակցության հայտ'],
    title1: ['', 'Contact Advanced Telephone Systems', 'Связь с Адванст Телефон Системс', 'Advanced Telephone Systems հետադարձ կապ'],
    desc: ['', 'We’re here to help and answer any questions you might have. We look forward to hearing from you!',
        'Мы здесь, чтобы помочь и ответить на любые Ваши вопросы.',
        'Մենք այստեղ ենք՝ օգնելու և պատասխանելու Ձեր ցանկացած հարցի։'],
    desc1: ['', 'Get in touch with Advanced Telephone Systems (ATS) for all your cloud PBX and communication needs. Find our location, address, and company details, or reach out to our support team for assistance. We’re here to help you connect seamlessly.',
        'Свяжитесь с Advanced Telephone Systems (ATS) для всех ваших потребностей в облачной АТС и связи. Найдите наше местоположение, адрес и данные компании или обратитесь в нашу службу поддержки за помощью. Мы здесь, чтобы помочь вам подключиться без проблем.\n',
        'Կապ հաստատեք Advanced Telephone Systems (ATS) հետ ձեր բոլոր ամպային PBX և կապի կարիքների համար: Տեսեք մեր գտնվելու վայրը, հասցեն և ընկերության մանրամասները կամ օգնության համար դիմեք մեր աջակցության թիմին: Մենք այստեղ ենք՝ օգնելու ձեզ անխափան կապ հաստատել:'],
    btn: ['', 'Submit', 'Отправить', 'Կապ հաստատել'],


}
export const log_reg = {
    login: ['', 'Sign in to your personal area', 'Войти', 'Մուտք գործել'],
    reset: ['', 'Reset your password', 'Сбросить пароль', 'Փոխել գաղտնաբառը'],
    sign: ['', 'Use the 15-day free version', 'Воспользуйтесь 15-дневной бесплатной версией', 'Օգտվել 15օրյա անվճար տարբերակից'],
    request: ['', 'If you already created a personal account?', 'Если вы уже создали личный кабинет?', 'Եթե ունեք անձնական հաշիվ'],
    desc: ['', 'We will use your phone number only for contact purposes',
        'Мы будем использовать ваш номер телефона только для связи',
        'Ձեր հեռախոսահամարը կօգտագործվի բացառապես կոնտակտային նպատակներով:'],
    descRes: ['', 'We will use your phone number only for contact purposes',
        'Мы будем использовать ваш номер телефона только для связи',
        'Ձեր հեռախոսահամարը կօգտագործվի բացառապես կոնտակտային նպատակներով:'],
    logRet: ['', 'Return to sign in page?', 'Вернуться на страницу входа?', 'Դեպի Մուտքի էջ'],
    regT: ['', 'Confirmation of registration',
        'Подтверждение регистрации',
        'Գրանցման հաստատում'],
    check: ['', 'Enter password', 'Введите пароль', 'Մուտքագրեք գաղտնաբառը'],
    regD: ['', 'Please wait, you will receive a code via SMS.',
        'Пожалуйста, подождите, вы получите код по SMS',
        'Խնդրում ենք սպասել, դուք կստանաք կոդ SMS հաղորդագրության միջոցով:'],

}

export const input = {
    telegram: ['', 'If you have telegram you will receive code for confirmation.If not, wait for SMS',
        'Если у вас есть Telegram, вы получите код для подтверждения.Если нет, ждите СМС',
        'Եթե ունեք Telegram, ապա կստանաք կոդ՝ հաստատման համար, Եթե ոչ` սպասեք SMS հաղորդագրության'],
    name: ['', 'Your full name', 'Имя фамилия', 'Անուն Ազգանուն'],
    company: ['', 'Your company name', 'Имя компании', 'Կազմակերպության անվանում'],
    email: ['', 'Your email', 'Электронная почта', 'էլ.փոստ'],
    phone: ['', 'Your contact phone', 'Номер телефона', 'Կոնտակտային հեռախոսահամար'],
    message: ['', 'Write a message to clarify your integration details', 'Напишите сообщение для уточнения деталей интеграции', 'Գրեք հաղորդագրություն՝ ձեր ինտեգրման մանրամասները պարզաբանելու համար'],
}

export const footer = {
    copyright: ['',
        'The company "IT-SPARK" was founded in 2010 in Yerevan.Our first project was the development of Call Center software.',
        'Компания «ИТ-СПАРК» была основана в 2010 году в Ереване.Нашим первым проектом была разработка программного обеспечения для колл-центра. ',
        '«IT-SPARK» ընկերությունը հիմնադրվել է 2010 թվականին Երևանում: Մեր առաջին նախագիծը Call Center ծրագրային ապահովման մշակումն էր:'],
    learn: ['', 'Learn More', 'Узнать больше', 'Իմացեք ավելին'],
    check: ['', 'About us', 'О нас', 'Մեր մասին'],
    partner: ['', 'Become a partner, send email to connect', 'Стать партнером, отправить электронное письмо', 'Դարձեք գործընկեր'],
    crm: ['', 'Integrate you CRM system to collect information', 'Интегрируйте свою CRM-систему', 'Ինտեգրեք Ձեր CRM համակարգը'],
    reg: ['', 'Register', 'Зарегистрироваться', 'Գրանցվեք համակարգում'],
    install: ['', 'Install Local PBX version', 'Установить версию локальной АТС', 'Տեղադրեք Լոկալ PBX տարբերակը'],
    api: ['', 'Use API to integrate our system', 'Используйте API для интеграции с нашей системой', 'Օգտագործեք API՝ մեր համակարգի հետ ինտեգրվելու համար'],
    contact: ['', 'Contact us', 'Обратная связь', 'Հետադարձ կապ'],

}

export const install = {
    postmanL: ['', 'Download postman collection', 'Бесплатно скачать коллекцию postman', 'Անվճար ներբեռնել postman հավաքածուն'],
    swaggerL: ['', 'View our swagger collection', 'Посмотрите нашу коллекцию swagger', 'Դիտեք մեր swagger հավաքածուն'],
    title: ['', 'Refer to ', 'Обратитесь к ', 'Ուսումնասիրեք '],
    link: ['', 'documentation', 'документации', 'դոկումենտացիան'],

    desc: ['', 'Check out documentation to install and use self-hosted version of PBX software',
        'Ознакомьтесь с документацией по установке и использованию локальной версии программного обеспечения АТС.',
        'Ծանոթացեք վերը նշված դոկումենտացիայի հետ, որպեսզի տեղադրել Լոկալ ԱՀԿ-ն Ձեր համակարգում'],
    titleV: ['', 'Refer to ', 'Обратитесь к ', 'Ուսումնասիրեք '],
    linkV: ['', 'documentation', 'документации', 'API դոկումենտացիան'],
    descV: ['', 'Check out documentation to install and use self-hosted version of PBX software',
        'Ознакомьтесь с документацией по установке и использованию виртуальной версии программного обеспечения “АТС”.',
        'Ծանոթացեք վերը նշված դոկումենտացիայի հետ, որպեսզի տեղադրել Վիրտուալ ԱՀԿ-ն Ձեր համակարգում'],

    postman: ['', 'You can Free Download postman collection by clicking the link bellow',
        'Вы можете бесплатно скачать коллекцию postman, перейдя по ссылке ниже.',
        'Դուք կարող եք Անվճար ներբեռնել postman հավաքածուն՝ սեղմելով ստորև նշված հղումը'],
    swagger: ['', 'Feel free to check out our swagger collection by clicking the link bellow',
        'Ознакомитесь с нашей коллекцией Swagger, перейдя по ссылке ниже.',
        'Ծանոթացեք մեր Swagger հավաքածուին՝ սեղմելով ստորև նշված հղումը']
}

export const lang = {
    en: 1,
    ru: 2,
    hy: 3,
}
